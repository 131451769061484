import React, { useEffect } from "react";

import BannerSection from "../../shared/components/BannerSection/BannerSection";
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import Benefit from "../components/Benefit/Benefit";
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import SubscriptionPlan from "../../shared/components/SubscriptionPlan/SubscriptionPlan";

import manDigitalIdentityBannerImg from '../../utils/assets/home/man-digital-identity-protected.png';
import protectProgramComputer from '../../utils/assets/home/protecting-program-computer.png';
import hackersProtectedYourSystem from '../../utils/assets/home/hackers-protected-your-system.png';
import allProtectionToolsBannerImg from '../../utils/assets/home/all-the-protecting-tools.png';

const Home = props => {

    useEffect(()=>{
        window.scroll(0,0);
        let sectionName = sessionStorage.getItem('sectionName');
        if (sectionName === null) sectionName = "home";
        const sectionElement = document.getElementById(sectionName);
        if (sectionElement) {
            const windowHeight = window.innerHeight;
            const docScrollTop = window.scrollY || document.documentElement.scrollTop;
            const sectionTop = sectionElement.getBoundingClientRect().top + docScrollTop;
            const centeredPosition = sectionTop - windowHeight / 2;
            const scrollToPosition = centeredPosition + 310;
            window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
        }
        return ()=>{
            sessionStorage.clear();
        }
    },[])
    const bannerSectionInfo =
    [
        {
            cardTitle: "The best price for protecting your personal information",
            cardBannerPosition : 1,
            cardButtonText: "join us",
            lengendBackground: manDigitalIdentityBannerImg
        },
        {
            cardTitle: "Protect Yourself from Getting Your Identity Stolen",
            cardBannerPosition : 2,
            cardButtonText: "Start now",
            lengendBackground: hackersProtectedYourSystem
        },
        {
            cardTitle: "Recovering and restoring your identity has never been easier",
            cardBannerPosition : 3,
            cardButtonText: "Sign Up",
            lengendBackground: allProtectionToolsBannerImg
        }

    ]

    const productDescription = {
        title: "What is our service?",
        img: protectProgramComputer
    }

    const subscriptionInformation = [
        {       
            subscriptionType: "MONTH",
            subscriptionPrice: "44.99",
            subscriptionTrialInfo: "Trial for 24 days at",
            subscriptionTrialPrice : "3.49",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {
            img: props.img,
            title : "Ready to relax and rest easy knowing you and your loved ones are covered 24/7?",
            description : "Find the protection and security you need to keep your identity and personal information safe from theft. Save money, time, and peace of mind knowing we have your back."
        }
    ]

 
    return (
        <React.Fragment>
            <BannerSection props={bannerSectionInfo[0]}/>
            <ProductDescription props={productDescription}/>
            <BannerSection props={bannerSectionInfo[1]}/>
            <Benefit/>
            <BannerSection props={bannerSectionInfo[2]}/>
            <SectionInfo title={"Become a member of our community"} color={"#73b4ff"}/> 
            <SubscriptionPlan  props={subscriptionInformation} />
        </React.Fragment>
    );
};

export default Home;