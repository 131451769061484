import React, { useEffect } from "react";


import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import SubscriptionPlan from "../../shared/components/SubscriptionPlan/SubscriptionPlan";


import priceBannerImg from '../../utils/assets/price/price.png';
import profilesLockImg from '../../utils/assets/price/profiles-lock.png';




const Price = props => {

    useEffect(()=>{
        window.scroll(0,0);
    },[])

     const bannerSectionInfo =
     {
        cardTitle: "Cutting-edge technology to protect and secure your identity",
        cardButtonText: "Start Now",
        lengendBackground: priceBannerImg
     }


     const subscriptionInformation = [
        {       
            subscriptionType: "MONTH",
            subscriptionPrice: "44.99",
            subscriptionTrialInfo: "Trial for 24 days at",
            subscriptionTrialPrice : "3.49",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {
            
            title : "Join and start improving your privacy and digital security.",
            img: profilesLockImg,
            imgDescription: "profile-lock",
        }
    ]

    return ( 
        <React.Fragment>
             <BannerSection props={bannerSectionInfo}/>
             <NavegationInfo page="price"/>
             <SectionInfo title={"Purchase protection now"} color={'#73b4ff'} />
             <SubscriptionPlan props={subscriptionInformation}/>
        </React.Fragment>
    );            
};

export default Price;