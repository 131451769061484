import React, {useEffect} from "react";

import BannerSection from '../../shared/components/BannerSection/BannerSection';
import unsubscribeBannerImg from '../../utils/assets/cancelService/pc-protected-system.png';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import Cancelation from '../components/Cancelation/Cancelation';

 

const CancelService = props => {
     const legendInfo =
     {
         cardTitle: "Would you like to unsubscribe from any of our services?",
         cardButtonText: "Call Us",
         lengendBackground: unsubscribeBannerImg
     }
     useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    return (
        <React.Fragment>
            <BannerSection props={legendInfo}/>
            <NavegationInfo page="Cancel service"/>
            <Cancelation/>
            
            
        </React.Fragment>
    );
};

export default CancelService;