import React, {useEffect}from "react";

import BannerSection from "../../shared/components/BannerSection/BannerSection";
import MembershipTerms from '../components/MembershipTerms/MembershipTerms';

import termsOfServiceBannerImg from '../../utils/assets/termsOfService/contratc-sing.png';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";

const TermsOfService = props => {

    const bannerSectionInfo =
     {
        cardTitle: "Terms and Conditions for using this Service",
        cardButtonText: "Make a Call",
        lengendBackground: termsOfServiceBannerImg
     }
     useEffect(()=>{
        window.scrollTo(0, 0);
        return ()=>{
            sessionStorage.clear();
        }
    }, [])

    return (
        <React.Fragment>
           <BannerSection props={bannerSectionInfo}/>
           <NavegationInfo page="Terms of service"/>
           <MembershipTerms/> 
        </React.Fragment>
    );
};

export default TermsOfService;