import React, {useEffect} from "react";


import BannerSection from "../../shared/components/BannerSection/BannerSection";
import Refund from "../components/Refund/Refund";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";

import refundPolicyLegen from '../../utils/assets/refundPolicy/refund-process.png';





const RefundPolicy = props => {
    const bannerSectionInfo =
    {
        cardTitle: "Unhappy with the service?",
        cardButtonText: "",
        lengendBackground: refundPolicyLegen
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
        return ()=>{
            sessionStorage.clear();
        }
    }, [])
    return (
        <React.Fragment>
         
            <BannerSection props={bannerSectionInfo}/>
            <NavegationInfo page="Refund policy"/>
            <Refund />
       
            
           
            
        </React.Fragment>
    );
};

export default RefundPolicy;