import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './BannerSection.css';

const BannerSection = ( {props} ) => {
   
    return(
        <section className="BannerSection__main-section-container" style={{backgroundImage: `url(${props.lengendBackground})`}} id="home"> 
            <CardInfo props={props}></CardInfo>
        </section> 
    );
};


const CardInfo = ( { props }) =>{
    const phoneNumber = "8883701687";
    let cardBodyText;
    const history = useHistory();
    const goToHandle = () => {
        if(window.location.pathname === "/"){

            if(  props.cardBannerPosition === 1 ||props.cardBannerPosition === 2 ){
                history.push('/contact');
            }
            else{
                window.open("https://portal.idguardllc.com/", '_blank');
            }
        }
        else if( window.location.pathname === '/about-us' || window.location.pathname === '/price'){
            history.push('/contact');
        }
        else if(window.location.pathname === '/privacy-policy' ){
            history.push('/contact');
        }
        else if ( window.location.pathname === '/terms-of-service' || window.location.pathname === '/cancel-service' ){
            window.location.href = `tel:${phoneNumber}`;
        }
    
    }
    

    switch( window.location.pathname ){
        case '/':
            if( props.cardBannerPosition === 1){
                   cardBodyText = (
                    <p>
                        Protect your identity and your family's online
                        privacy with our <span className="CardInfo__important-text"> next generation technology for the
                        protection and security of your information. 
                        </span>
                    </p>
                   ) 
            }
            else if (props.cardBannerPosition === 2){
                cardBodyText = (
                    <p>
                        Save time, money and headaches with our world-
                        class services. Discover the benefits of being under
                        our <span className="CardInfo__important-text">protection 24 hours a day, seven days a
                        week.
                        </span>
                    </p>
                )
            }
            else{
                cardBodyText =(
                    <p>
                        All the best identity theft protection tools in one
                        place. Rest assured you are backed by <span className="CardInfo__important-text">experts in
                        identity protection and security management.
                        </span>
                    </p>
                )
            }
            break;

        case '/price':

             cardBodyText=(
                <p>
                    To help you keep thieves at bay, our next-
                    generation system offers a wide range of <span className="CardInfo__important-text"> valuable 
                    tools at an affordable price and backed up by 24/7
                    support. 
                    </span>
                </p>
             )

            break;
        
        case '/contact':
            cardBodyText = (
                <p>
                    Use our online profile protection advice, expert 
                    legal assistance, and security configuration
                    upgrades to <span className="CardInfo__important-text">make a difference in your digital life.</span>
                </p> 
            )
            break;
        
        case '/cancel-service':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">It is very easy for you to cancel your service.</span> To
                    help us improve your experience, please tell us why
                    you are canceling. 
                </p>
            )
                
            break;

        case '/terms-of-service':
            cardBodyText=(
                <p>
                    If you have any questions or would like to receive
                    more information, <span className="CardInfo__important-text">please feel free to contact us!</span> 
                </p>
            )
            break;

        case '/privacy-policy':
                cardBodyText=(
                    <p>
                        At ID Guard, the privacy of your personal 
                        information is important to us. If you have any
                        questions or concerns, <span className="CardInfo__important-text">Please contact us!</span>
                    </p>
                )
        break;
        case '/refund-policy':
                    cardBodyText=(
                        <p>
                            <span className="CardInfo__important-text">We appreciate your feedback.</span> Please let us know
                            what went wrong and how we can improve. If you
                            want to request a refund for your purchase, please
                            follow these steps 
                        </p>
                    )
        break;
        case '/about-us':
            cardBodyText=(
                <p>
                    Our services are designed to ensure that you have
                    all the tools <span className="CardInfo__important-text">you need to protect your identity and
                    enhance your security. 
                    </span>

                </p>
            )
            break;
        case '/checkout':
            cardBodyText=(
                <p>
                     Clear your doubts, get incredible advice, get the
                     next generation of tools, and <span className="CardInfo__important-text">have 24/7 access to
                     the experts in the field with our exclusive plan.
                     </span>

                </p>
            )
            break;
        default :
           cardBodyText = null;
        break;
    }

    
    return(
       
        <div className="BannerSection__main-card-info-container">

            <div className="BannerSection__main-title-container">
                <h2 className="text-left">{props.cardTitle}</h2>
            </div>
            <div className="BannerSection__main-body-container">
                {cardBodyText}
            </div>
            {
                props.cardButtonText.trim() !== "" ? (
                    <div className="BanneSection__main-btn-container">
                        <div className="BannerSection__main-button-container">
                        <div className="BannerSection__inner-button-container">
                            <a href="" onClick={(e) => { e.preventDefault(); goToHandle()}}>
                                {props.cardButtonText}
                            </a>
                        </div>
                    </div>
                    </div>
                ) : (
                    <div className="BannerSection__button-container" style={{ minWidth : '10em'}}>
                    </div>
                )
            }
        </div>
     
    )
}


export default BannerSection;